<template>
  <base-dialog
    :showModal="showModal"
    showCloseButton
    :cancelButtonText="$t('btn.clear')"
    @confirm-action="confirmAction"
    @hide-modal="closeModal"
    @cancel-action="cancelAction"
  >
    <h5 class="text-center">{{ $t("filter.dt_fil") }}</h5>
    <label class="mt-2">{{ $t("home.cat") }}</label>
    <v-select
      :options="optionCategory"
      label="label"
      :reduce="(option) => option.value"
      v-model="filterOptions.id_category"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("home.sub_cat") }}</label>
    <v-select
      :options="optionSubCategory"
      label="label"
      :reduce="(option) => option.value"
      v-model="filterOptions.id_sub_category"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("home.build") }}</label>
    <v-select
      :options="optionBuilding"
      label="label"
      :reduce="(option) => option.value"
      v-model="filterOptions.id_location_building"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("home.fl") }}</label>
    <v-select
      :options="optionFloor"
      label="label"
      :reduce="(option) => option.value"
      v-model="filterOptions.id_location_floor"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("home.department") }}</label>
    <v-select
      :options="optionDepartment"
      label="label"
      :reduce="(option) => option.value"
      v-model="filterOptions.id_location_department"
      class="dropdown-style"
      :clearable="false"
    />
    <label class="mt-2">{{ $t("home.zone") }}</label>
    <v-select
      :options="optionZone"
      label="label"
      :reduce="(option) => option.value"
      v-model="filterOptions.id_location_zone"
      class="dropdown-style"
      :clearable="false"
    />
  </base-dialog>
</template>

<script>
import BaseDialog from "../BaseDialog.vue";
export default {
  emits: ["hide-modal", "confirm-action", "cancel-action", "set-filter"],
  components: {
    BaseDialog,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      optionChoice: {},
      filterOptions: {
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
      },
    };
  },
  computed: {
    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.filterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.filterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.filterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: `${item.code} - ${item.name}`,
                value: item.id_location_department,
              };
            })
          ),
        ];
        // console.log("Department list: ", departmentList);
        return departmentList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        // console.log("zone list: ", zoneList);
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("hide-modal");
    },
    confirmAction() {
      this.$emit("confirm-action", this.filterOptions);
    },
    cancelAction() {
      this.filterOptions = {
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
      };
      this.$emit("cancel-action", this.filterOptions);
    },
  },
  async created() {
    await this.$store.dispatch("loadOptionChoice");
    this.optionChoice = this.$store.getters.getfilterOption;
    this.$emit("set-filter", this.filterOptions);
  },
};
</script>

<style lang="scss" scoped>
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  ::v-deep .vs__search {
    background-color: white !important;
  }
}

label {
  text-align: left;
  display: block;
}
</style>
